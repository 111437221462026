import { routerReducer } from '@dealroadshow/connected-next-router';
import { combineReducers } from 'redux';
import companies from '@/users/application/actions/companies/reducer';
import user from '@/users/application/actions/reducer';
import tenant from '@/Framework/Tenant/State/Redux/reducer';
import containerReducer from '@/Framework/DI/containerReducer';

export default combineReducers({
  router: routerReducer,
  container: containerReducer,
  match: (state) => state || null,
  companies,
  user,
  tenant,
});
